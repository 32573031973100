import React, { Fragment, useState } from 'react'
import { Box, Button, CardMedia, CircularProgress, Container, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { images } from '../../assets';
import { useForm } from 'react-hook-form';
import { colors } from '../../assets/colors';
import styled from '@emotion/styled';
import EventServices from '../../api/events/EventServices';
import { SuccessToaster } from '../../components/toaster/Toaster';
import { useLocation } from 'react-router-dom';
import { baseUrl } from '../../axios';

const InputField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: colors.primaryColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primaryColor,
    },
    "&.Mui-disabled fieldset": {
      borderColor: "#c4c4c4",
    }
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: colors.primaryColor,
  },
  "&:hover .MuiInputLabel-root.Mui-disabled": {
    color: "#c4c4c4",
  },
  "&:hover .MuiInputLabel-root": {
    color: colors.primaryColor,
  }
})

const options = [
  {
    _id: 1,
    name: "VIP"
  },
  {
    _id: 2,
    name: "Premium"
  },
  {
    _id: 3,
    name: "Standard"
  }
];

const prices = [
  {
    type: "Seat",
    prices: [
      {
        _id: 1,
        type: "VIP",
        price: 30000
      },
      {
        _id: 2,
        type: "Premium",
        price: 25000
      },
      {
        _id: 3,
        type: "Standard",
        price: 23000
      }
    ],
  },
  {
    type: "Table",
    prices: [
      {
        _id: 1,
        type: "VIP",
        price: 300000
      },
      {
        _id: 2,
        type: "Premium",
        price: 250000
      },
      {
        _id: 3,
        type: "Standard",
        price: 200000
      },
    ],
  },
];

const advisionPrices = [
  {
    _id: 1,
    price: 30000
  },
  {
    _id: 2,
    price: 25000
  },
  {
    _id: 3,
    price: 20000
  }
];

const type = ["Table", "Seat"];

function Form() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [tableOrSeats, setTableOrSeats] = useState(null);
  const [price, setPrice] = useState(null);
  const [noOfSeats, setNoOfSeats] = useState(null);
  const [loading, setLoading] = useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const eventType = searchParams.get("event");
  const { state } = useLocation();

  const handleFormSubmit = async (formData) => {
    setLoading(true);
    const obj = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phone,
      companyName: formData.companyName,
      book: selectedType?.toLowerCase(),
      totalTables: selectedType == "Table" ? +tableOrSeats : 0,
      totalSeats: eventType == "advision" ? +noOfSeats : selectedType == "Seat" ? +tableOrSeats : 0,
      category: selectedCategory?.name.toLowerCase(),
      price: price,
      passes: +formData.passes
    };
    try {
      const result = await EventServices.createEvent(eventType, obj);
      if (result.responseCode == 200) {
        SuccessToaster(result.message);
        setSelectedCategory(null);
        setSelectedType(null);
        setPrice(null);
        reset();
        SuccessToaster(result?.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // For Dragons Of Pakistan
  const handlePrice = (quantity, category = selectedCategory, type = selectedType) => {
    if (category && type && quantity) {
      const selectedPriceType = prices.find(item => item.type === type);
      if (selectedPriceType) {
        const priceDetail = selectedPriceType.prices.find(item => item._id === category._id);
        if (priceDetail) {
          const totalPrice = priceDetail.price * quantity;
          setPrice(totalPrice);
        }
      }
    } else {
      setPrice(null);
    }
  };

  const handlePassPrice = (e) => {
    const value = e.target.value;
    const totalPrice = value * state?.price;
    if (totalPrice > 0) {
      setPrice(totalPrice);
    } else {
      setPrice("");
    }
  }

  const handleCategoryChange = (e) => {
    const selected = options.find(item => item._id == e.target.value);
    setSelectedCategory(selected);
    handlePrice(tableOrSeats, selected, selectedType);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    handlePrice(tableOrSeats, selectedCategory, e.target.value);
  };

  const handleQuantityChange = (e) => {
    setTableOrSeats(e.target.value);
    handlePrice(e.target.value, selectedCategory, selectedType);
  };
  //For Dragons Of Pakistan

  //For Advision
  const handleAdvisionPrice = (seats, category = selectedCategory) => {
    if (category && seats) {
      const selectedPrice = advisionPrices.find((item) => item._id === category._id)?.price;
      const totalPrice = selectedPrice * seats;
      setPrice(totalPrice);
    } else {
      setPrice(null);
    }
  };

  const handleAdvisionCategoryChange = (e) => {
    const selected = options.find((item) => item._id == e.target.value);
    setSelectedCategory(selected);
    handleAdvisionPrice(noOfSeats, selected);
  };

  const handleAdvisionSeatsChange = (e) => {
    const seats = e.target.value;
    setNoOfSeats(seats);
    handleAdvisionPrice(seats);
  };
  //For Advision

  return (
    <Grid container>
      <Grid item md={12} sm={12} xs={12}>
        <CardMedia
          component={"img"}
          src={baseUrl + state?.formImg}
          sx={{
            width: "100%",
            height: "100%"
          }}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Container>
          <Grid
            container
            spacing={2}
            sx={{ p: 2 }}
            component={"form"}
            onSubmit={handleSubmit(handleFormSubmit)}
            justifyContent={"flex-start"}
          >
            <Grid item md={12}>
              <Typography variant='h3' sx={{ textAlign: "center", fontSize: { md: "48px", xs: "40px" } }}>Registration Form</Typography>
            </Grid>
            {eventType.includes("dragons-of-pakistan") ? (
              <Fragment>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Name"}
                    {...register("name", {
                      required: "Your name"
                    })}
                    error={errors?.name && true}
                    helperText={errors?.name?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Email"}
                    {...register("email", {
                      required: "Your email"
                    })}
                    error={errors?.email && true}
                    helperText={errors?.email?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Phone"}
                    {...register("phone", {
                      required: "Your phone"
                    })}
                    error={errors?.phone && true}
                    helperText={errors?.phone?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Company Name"}
                    {...register("companyName", {
                      required: "Your company name"
                    })}
                    error={errors?.companyName && true}
                    helperText={errors?.companyName?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    select
                    fullWidth
                    variant={"outlined"}
                    label={"Category"}
                    value={selectedCategory != null ? selectedCategory?._id : null}
                    {...register("category", {
                      required: "Category",
                      onChange: (e) => handleCategoryChange(e)
                    })}
                    error={errors?.category && true}
                    helperText={errors?.category?.message}
                  >
                    {options.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}

                  </InputField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    select
                    fullWidth
                    variant={"outlined"}
                    label={"Table OR Seat"}
                    value={selectedType}
                    {...register("type", {
                      required: "Table OR Seat",
                      onChange: (e) => handleTypeChange(e)
                    })}
                    error={errors?.type && true}
                    helperText={errors?.type?.message}
                  >
                    {type.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}

                  </InputField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    disabled={(selectedCategory && selectedType) ? false : true}
                    fullWidth
                    variant={"outlined"}
                    label={
                      selectedType == "Table" ? "No of Table"
                        : selectedType == "Seat" ? "No of Seat"
                          : "Select Table OR Seat"
                    }
                    {...register("tables", {
                      required: "Number of tables",
                      onChange: (e) => handleQuantityChange(e)
                    })}
                    error={errors?.tables && true}
                    helperText={errors?.tables?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    value={
                      selectedCategory != null && selectedType != null && tableOrSeats != null && tableOrSeats != ""
                        ? `${price} PKR` : selectedCategory == null || selectedType != null ? ""
                          : selectedCategory != null || selectedType == null ? ""
                            : ""
                    }
                    fullWidth
                    variant={"outlined"}
                    label={"Price"}
                    {...register("price", {
                      required: price == null ? "Price" : false,
                    })}
                    error={errors?.price && true}
                    helperText={errors?.price?.message}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Fragment>
            ) : eventType.includes("advision") ? (
              <Fragment>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Name"}
                    {...register("name", {
                      required: "Your name"
                    })}
                    error={errors?.name && true}
                    helperText={errors?.name?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Email"}
                    {...register("email", {
                      required: "Your email"
                    })}
                    error={errors?.email && true}
                    helperText={errors?.email?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Phone"}
                    {...register("phone", {
                      required: "Your phone"
                    })}
                    error={errors?.phone && true}
                    helperText={errors?.phone?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Regd. Company Name & NTN (if any)"}
                    {...register("companyName", {
                      required: "Your company name"
                    })}
                    error={errors?.companyName && true}
                    helperText={errors?.companyName?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    select
                    fullWidth
                    variant={"outlined"}
                    label={"Category"}
                    value={selectedCategory != null ? selectedCategory?._id : null}
                    {...register("category", {
                      required: "Category",
                      onChange: (e) => handleAdvisionCategoryChange(e)
                    })}
                    error={errors?.category && true}
                    helperText={errors?.category?.message}
                  >
                    {options.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}

                  </InputField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    disabled={selectedCategory == null ? true : false}
                    variant={"outlined"}
                    label={"No of Seats"}
                    {...register("tables", {
                      required: "Number of seats",
                      onChange: (e) => handleAdvisionSeatsChange(e)
                    })}
                    error={errors?.tables && true}
                    helperText={errors?.tables?.message}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    value={
                      selectedCategory != null && noOfSeats != null && noOfSeats != "" ? `${price} PKR`
                        : selectedCategory == null || noOfSeats != null ? ""
                          : selectedCategory != null || noOfSeats == null ? ""
                            : ""
                    }
                    fullWidth
                    variant={"outlined"}
                    label={"Price"}
                    {...register("price", {
                      required: price == null ? "Price" : false,
                    })}
                    error={errors?.price && true}
                    helperText={errors?.price?.message}
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
              </Fragment>
            ) : (
              <Fragment>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Name"}
                    {...register("name", {
                      required: "Your name"
                    })}
                    error={errors?.name && true}
                    helperText={errors?.name?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Email"}
                    {...register("email", {
                      required: "Your email"
                    })}
                    error={errors?.email && true}
                    helperText={errors?.email?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Phone"}
                    {...register("phone", {
                      required: "Your phone"
                    })}
                    error={errors?.phone && true}
                    helperText={errors?.phone?.message}
                  />

                </Grid>
                <Grid item md={6} xs={12}>
                  <InputField
                    fullWidth
                    variant={"outlined"}
                    label={"Regd. Company Name & NTN (if any)"}
                    {...register("companyName", {
                      required: "Your company name"
                    })}
                    error={errors?.companyName && true}
                    helperText={errors?.companyName?.message}
                  />
                </Grid>
                {state.price != null && (
                  <Fragment>
                    <Grid item md={6} xs={12}>
                      <InputField
                        fullWidth
                        variant={"outlined"}
                        label={"Number Of Passes"}
                        {...register("passes", {
                          onChange: (e) => handlePassPrice(e)
                        })}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <InputField
                        value={price != "" && price != null ? `${price} PKR` : ""}
                        inputProps={{ readOnly: true }}
                        fullWidth
                        variant={"outlined"}
                        label={"Price"}
                        {...register("passPrice")}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Fragment>
            )}
            <Grid item md={12}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  disabled={loading}
                  variant='contained'
                  type='submit'
                  sx={{
                    background: colors.primaryColor,
                    border: "2px solid transparent",
                    p: "6px 24px",
                    fontWeight: 600,
                    borderRadius: "8px",
                    boxShadow: "none",
                    ":hover": {
                      boxShadow: "none",
                      background: colors.white,
                      border: `2px solid ${colors.primaryColor}`,
                      color: colors.blackShade
                    }
                  }}
                >
                  {loading ? <CircularProgress sx={{ "&.MuiCircularProgress-root": { width: "24px !important", height: "24px !important", color: colors.primaryColor } }} /> : "Submit"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Form
