import AdmeetRoutes from "./Admeet.routes";
import { post, get } from "../index";

const AdmeetService = {
  register: async (obj) => {
    const result = await post(AdmeetRoutes.register, obj);
    return result;
  },

  getBlogById: async (id) => {
    const result = await get(AdmeetRoutes.getAllAdleaks + `?id=${id}`);
    return result;
  },
};

export default AdmeetService;
