import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./styles.css";

// import required modules
import { Autoplay, FreeMode, Navigation, Thumbs, Pagination } from "swiper/modules";

import { Box, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Banner({ images, baseUrl }) {
  const navigate = useNavigate();

  const handleNavigate = (data) => {
    console.log("🚀 ~ handleNavigate ~ data:", data)
    if (data.blogUrl) {
      navigate(data.blogUrl);
    } else {
      if (data.url.includes("https://adstreet.com.pk")) {
        const path = data.url.split("https://adstreet.com.pk/")[1];
        console.log("🚀 ~ handleNavigate ~ path:", path)
        navigate(path, { state: data });
      } else {
        const link = document.createElement("a");
        link.href = data.url;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  return (
    <Swiper
      slidesPerView={1}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      navigation={true}
      modules={[Pagination, FreeMode, Navigation, Thumbs]}
      className="mySwiper"
    >
      {images.map((item, index) => (
        <SwiperSlide key={index}>
          <CardMedia
            component={"img"}
            src={baseUrl + item?.imageUrl}
            alt={"image"}
            onClick={() => handleNavigate(item)}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Banner;
