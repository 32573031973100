import { post } from "../index";
import EventRoutes from "./Event.routes";

const EventServices = {
  createDragonEvent: async (obj) => {
    const result = await post(EventRoutes.createDragonEvent, obj);
    return result;
  },

  createAdvisionEvent: async (obj) => {
    const result = await post(EventRoutes.createAdvisionEvent, obj);
    return result;
  },

  createEvent: async (event, obj) => {
    const result = await post(EventRoutes.createEvent + `?event=${event}`, obj);
    return result;
  },
};

export default EventServices;