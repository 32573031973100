import AdleaksRoutes from "./Adleaks.routes";
import { get } from "../index";

const AdleaksService = {
  getAllAdleaks: async (page, limit, type, id) => {
    const result = await get(AdleaksRoutes.getAllAdleaks + `?page=${page}&limit=${limit}&type=${type}`);
    return result;
  },

  getBlogById: async (id) => {
    const result = await get(AdleaksRoutes.getAllAdleaks + `?id=${id}`);
    return result;
  },
};

export default AdleaksService;
