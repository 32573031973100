import { Container, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/colors/index";
import BasicChips from "../../components/blogDetailsChip";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../../axios";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BlogSM from "../../components/blogSocialMedia/BlogSM";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import parse from "html-react-parser";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import BlogServices from "../../api/blog/BlogService";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import AdmeetService from "../../api/admeet/AdmeetService";

const AdmeetBlogDetail = ({ backgroundColor }) => {
  const [item, setItem] = useState(null);
  const location = useLocation();
  const { id } = useParams();
  // const item = location?.state;

  const returnToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`/admeet-registeration`, { state: item });
  };

  const navigate = useNavigate();

  const htmlContent = item?.content;

  // Create a temporary div element to parse the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;
  let oembedTag;
  let oembedUrl;

  if (tempDiv.querySelector("oembed")) {
    oembedTag = tempDiv.querySelector("oembed");
    oembedUrl = oembedTag.getAttribute("url");
  }

  let embedUrl = oembedUrl?.replace(/youtu\.be\/([^\?]+)/, "youtube.com/watch?v=$1");
  embedUrl = embedUrl?.replace("/watch?v=", "/embed/");

  console.log(embedUrl);

  const getBlogById = async () => {
    try {
      const result = await AdmeetService.getBlogById(id);
      if (result.responseCode == 200) {
        setItem(result.data.blogs[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogById()
  }, [id]);

  return (
    <>
      <Container>
        <Box component={"article"}>
          <Grid container margin={"0 auto"} sx={{ width: "87%" }}>
            {/* Blog details */}
            <Grid item xs={12}>
              <Grid container mt={1} justifyContent={"space-between"} spacing={1}></Grid>
            </Grid>
            {/* Image */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component={"img"}
                    src={baseUrl + item?.image}
                    sx={{
                      width: { xs: "100%", sm: "100%", lg: "100%" },
                      height: { xs: "200px", sm: "300px", lg: "590px" },
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "block",
                      margin: "0 auto",
                      mt: 4,
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    component={"h1"}
                    sx={{
                      m: 0,
                      width: "100%",
                      fontSize: "32px",
                      mt: 1,
                      textAlign: "center",
                      color: colors.primaryColor,
                    }}
                  >
                    Ready to join the event?
                    <Button
                      onClick={() => returnToTop()}
                      size="medium"
                      sx={{
                        backgroundColor: colors.primaryColor,
                        color: colors.white,
                        textTransform: "capitalize",
                        ml: 2,
                        fontWeight: "bold",
                        "&:hover": {
                          backgroundColor: colors.primaryColor,
                        },
                      }}
                    >
                      Register now
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Title */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Box
                    component={"h1"}
                    sx={{
                      m: 0,
                      width: "100%",
                      fontSize: "32px",
                      mt: 2,
                    }}
                  >
                    {item?.title}
                  </Box>
                </Grid>
              </Grid>

              {/* Comments */}
            </Grid>
            <Grid container columnSpacing={"12px"}>
              {/* Facebook */}
              {item?.additional?.f_link && (
                <Grid item xs={6} lg={1.81} sm={3} mt={2}>
                  <Grid container>
                    <Grid item>
                      <a className="btn btn-icon btn-facebook fb-title" href={item?.additional?.f_link} target="_blank">
                        <i className="bi bi-facebook class-two"></i>

                        <span className="class-three">Facebook</span>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* Twitter */}
              <Grid item xs={6} md={1.62}>
                {item?.additional?.t_link && (
                  <Grid item mt={2}>
                    <Grid container>
                      <Grid item>
                        <a className="btn btn-icon btn-twitter" href={item?.additional?.t_link} target="_blank">
                          <i className="fa fa-twitter"></i>
                          <span className="twitter-btn">Twitter</span>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* LinkedIn */}
              <Grid item xs={6} md={1.75}>
                {item?.additional?.l_link && (
                  <Grid item mt={2}>
                    <Grid container>
                      <Grid item>
                        <a className="btn btn-icon btn-linkedin" href={item?.additional?.l_link} target="_blank">
                          <i className="bi bi-linkedin"></i>
                          <span>LinkedIn</span>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* WhatsApp */}
              <Grid item xs={6} md={4}>
                {item?.additional?.w_link && (
                  <Grid item mt={2}>
                    <Grid container>
                      <Grid item>
                        <a className="btn btn-icon btn-whatsapp" href={item?.additional?.w_link} target="_blank">
                          <i className="bi bi-whatsapp"></i>
                          <span>WhatsApp</span>
                        </a>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Interest button */}
              {/* <Grid item xs={12} sm={12} lg={2}>
                <Grid item xs={12} sm={12} md={2} mt={3}>
                  {item?.type === "Admeet" && (
                    <Button
                      onClick={() => returnToTop()}
                      sx={{
                        backgroundColor: colors.primaryColor,
                        color: colors.white,
                        pl: 1,
                        width: { xs: "100%", md: "127px" },
                        textTransform: "capitalize",
                        height: "36px",
                        "&:hover": {
                          backgroundColor: colors.primaryColor,
                        },
                      }}
                    >
                      Interested ?
                    </Button>
                  )}
                </Grid>
              </Grid> */}
            </Grid>

            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} mt={2}>
                  <Grid container>
                    <Grid item>
                      <CalendarTodayIcon sx={{ fontSize: "15px" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <BasicChips
                        sx={{
                          width: "100px",
                          backgroundColor: "red",
                          display: "block",
                          fontSize: "15px",
                        }}
                        content={moment(item?.date).format("LL")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    component={"p"}
                    sx={{
                      fontSize: { xs: "10px", md: "16px" },
                      color: colors.blogTextColor,
                      pb: 5,
                    }}
                    className="parsed-html"
                  >
                    {item?.content && parse(item?.content)}
                  </Box>

                  {embedUrl && (
                    <iframe
                      style={{
                        paddingBottom: "20px",
                      }}
                      width="100%"
                      height="400"
                      display="block"
                      src={embedUrl}
                      title="Video"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AdmeetBlogDetail;
